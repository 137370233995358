window.Track = function () {
  this.init = function() {
    const outboundLinks  = document.querySelectorAll('.was-track');

    if (outboundLinks.length > 0) {
      for(let i in outboundLinks){
        if(outboundLinks.hasOwnProperty(i)){
          outboundLinks[i].addEventListener('click', handleTrack);
        }
      }
    }
  };

  function handleTrack(e) {
    const url = e.currentTarget.href;

    ga('send', 'event', 'outbound', 'click', url, {
      'transport': 'beacon',
      'hitCallback': function() {
        document.location = url;
      }
    });
  }
};

new Track().init();
